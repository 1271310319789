var s = document.createElement('script');
// @TODO figure out how to use env variables here
// s.src = `${process.env.APPDATE_CDN_URL}/index.js`;
// s.src = `http://cdn.localhost:8888/index.js`;
s.src = `https://cdn.appdate.io/index.js`;
// test mobile
// s.src = 'http://app.192.168.0.21:8888/index.js';
s.async = true;
document.head.appendChild(s);
s.onload = function () {
  appdate.init("jhJzOW6yxdH4phz77kUat");
  // appdate.identify('1234');
  const listener = (data, error) => {
    // appdate.notifications.removeListener( 'click', listener )
  }

  appdate.notifications.on('click', listener)
}